export class RegistrationForm {
    email = '';
    password = '';
    confirmPassword = '';
    firstName = '';
    lastName = '';
    companyName = '';
    phoneNumber = '';
}

export class RegistrationDetails {
    token = '';
    providerName = '';
    businessEmail = '';
    phoneNumber = '';
    descriptionOfThePlace = '';
    website = '';
    operationContactFullName = '';
    operationContactPhoneNumber = '';
    operationContactEmail = '';
    financeDepartmentFullName = '';
    financeDepartmentPhoneNumber = '';
    financeDepartmentEmail = '';
    businessAddress = '';
    companyId = '';
    nameOfTheBusinessForInvoice = '';
    logo = '';
}

export class ResetPasswordForm {
    token = '';
    password = '';
    confirmPassword = '';
}
